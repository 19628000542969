import CentreButton from '@/components/styled/CustomButtons/CentreButton/CentreButton';
import { useGetGiveawayItemLazyQuery } from '@/generated/projectlb-hasura';
import { useAuthContext } from '@/hooks/use-auth-context';
import { Winner } from '@/pages/give/[id]';
import Image from 'next/image';
import { useState } from 'react';
import styles from './ItemWinner.module.scss';
import { toast } from 'react-toastify';
import { useAmpliError } from '@/hooks/use-ampli-error';

interface GiveWinnerProps {
  winner: Winner;
  giveawayId: string;
  itemClaimed: boolean;
}

const ItemWinner = ({ winner, giveawayId, itemClaimed }: GiveWinnerProps) => {
  const { winner_avatar, winner_id, winner_name } = winner;
  const [isItemClaimed, setIsItemClaimed] = useState(itemClaimed);
  const { userId, isAuthorized, accessToken } = useAuthContext();
  const showButton = isAuthorized && winner_id === userId;
  const [getGiveawayItem, { loading }] = useGetGiveawayItemLazyQuery();
  const { errorTrack } = useAmpliError();
  const handleButtonClick = () => {
    getGiveawayItem({
      context: { token: accessToken },
      fetchPolicy: 'no-cache',
      variables: {
        giveaway_id: giveawayId,
      },
      onCompleted(data) {
        const res = data.GiveawayGetItem;

        if (res?.success) {
          toast.success('Награда успешно получена');
          setIsItemClaimed(true);
        } else {
          errorTrack({ message: 'ItemWinner. Ошибка получения награды. ' + res?.error, isAuthorized });
          toast.error(res?.error);
        }
      },
      onError() {
        errorTrack({ message: 'ItemWinner. Ошибка получения награды.', isAuthorized });
        toast.error('Произошла ошибка, попробуйте позже');
      },
    });
  };
  return (
    <div className={styles.container}>
      <div className={styles.container_info}>
        <div className={styles.container_name}>
          <Image src={winner_avatar!} width={44} height={44} />
          <p className={styles.timer_text}>{winner_name}</p>
        </div>
        {showButton && (
          <CentreButton
            text={isItemClaimed ? 'Получена' : 'Забрать'}
            disabled={isItemClaimed}
            loading={loading}
            variant="outlined"
            className={styles.reward_button}
            onClick={handleButtonClick}
          />
        )}
      </div>
    </div>
  );
};

export default ItemWinner;

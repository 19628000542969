import { track } from '@amplitude/analytics-browser';
import { useRouter } from 'next/router';
import { captureEvent } from '@/utils/analytics';

interface IErrorProperties {
  message: string;
  from?: string;
  isAuthorized?: boolean;
}

export const useAmpliError = () => {
  const { asPath } = useRouter();

  const errorTrack = ({ message, from = asPath, isAuthorized }: IErrorProperties) => {
    captureEvent('error', {
      msg: message,
      from: from,
      auser: isAuthorized,
    });
    track('error', {
      msg: message,
      from: from,
      auser: isAuthorized
    });
  };
  return { errorTrack };
};
